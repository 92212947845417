<template>
  <div class="fwtc">
    <div class="main">
      <div class="overlayWrap" @click.stop>

        <div class="overlayHead">
          <div class="btn" @click="handlePrevDay()">
            <van-icon name="arrow-left" />
          </div>
          <div class="dateInner">{{ currentRq }}</div>
          <div class="btn" @click="handleNextDay()">
            <van-icon name="arrow" />
          </div>
        </div>

        <div class="overlayChart">
          <div id="chartContainer" class="chartContainer"></div>
        </div>

        <div class="overlayDesc" v-if="currentDetail">
          <van-row>
            <van-col span="12">
              <div class="descItem">
                <div class="descTitle">睡眠时长</div>
                <div class="descValue">{{ getTimeOfMin(currentDetail.smsc) }}</div>
              </div>
            </van-col>
            <van-col span="12">
              <div class="descItem">
                <div class="descTitle">清醒次数</div>
                <div class="descValue">{{ currentDetail.qxcs }}</div>
              </div>
            </van-col>
            <van-col span="12">
              <div class="descItem">
                <div class="descTitle">入睡时间</div>
                <div class="descValue">{{ currentDetail?.rssj?.substring(0, 16) }}</div>
              </div>
            </van-col>
            <van-col span="12">
              <div class="descItem">
                <div class="descTitle">起床时间</div>
                <div class="descValue">{{ currentDetail?.qcsj?.substring(0, 16) }}</div>
              </div>
            </van-col>
            <van-col span="12">
              <div class="descItem">
                <div class="descTitle">深睡</div>
                <div class="descValue">{{ getTimeOfMin(currentDetail.sssc) }}</div>
              </div>
            </van-col>
            <van-col span="12">
              <div class="descItem">
                <div class="descTitle">浅睡</div>
                <div class="descValue">{{ getTimeOfMin(currentDetail.qssj) }}</div>
              </div>
            </van-col>
          </van-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

import { mapActions, mapState } from 'vuex';
import axios from 'axios';
import dayjs, { Dayjs } from 'dayjs';
import * as echarts from 'echarts';

export default {
  name: 'Fwtc',
  data() {
    return {
      list: [],
      currentSjhm: '',
      currentRq: '',
      currentTime: null,
      loading: false,
      currentDetail: null,
      currentJkList: [],
      store: null,
    }
  },
  created() {
    this.handleClickItem();
  },
  mounted() {
    this.myChart = echarts.init(document.getElementById('chartContainer'));
    // 绘制图表
    this.myChart.setOption({
      title: {
        text: 'ECharts 入门示例',
        show: false,
      },
      dataZoom: [
        {
          type: 'slider',
          bottom: '15%',
        },
      ],
      grid: {
        top: '5%',
        left: '5%',
        right: '5%',
        bottom: '20%'
      },
      tooltip: {},
      xAxis: {
        type: 'time',
      },
      yAxis: {
        show: false,
        min: 0,
        max: 10
      },
      legend: {
        data: ['深睡', '浅睡', '醒/梦'],
        bottom: 5,
        align: 'auto'
      },
      series: [
        {
          name: '深睡',
          type: 'bar',
          data: [['2023-6-15 01:00', 2]],
        },
        // {
        //   barCategoryGap:'0',
        //   name: '浅睡',
        //   type: 'bar',
        //   data: [['2023-6-15 01:00',4], ['2023-6-15 02:00', 4]],
        // },
        {

          name: '醒/梦',
          type: 'bar',
          data: [['2023-6-15 02:00', 6], ['2023-6-15 03:00', 6]],
        }
      ]
    });
  },
  computed: {
    ...mapState(['taocan_order']),
  },
  methods: {
    ...mapActions(['get_taocan_order']),
    async getOneSellpData() {
      let { data: res } = await axios.get('/api/api/get_one_sleep_data', {
        params: {
          rq: this.currentTime?.subtract(1, 'day').format('YYYY-MM-DD') || '',
          sjhm: this.currentSjhm
        }
      });
      // console.log(res);
      let SumTemp = [];
      res.data?.sleepData?.forEach(f => {
        let sLen = Math.floor(f.sleepLen / 60);
        let temp = [];
        for (let i = 0; i < sLen; i++) {
          temp.push([
            dayjs(f.start_time).add(i + 1, 'minute').format('YYYY-MM-DD hh:mm'),
            f.sleepType == 241 ? 10 : 6
          ])
        }
        SumTemp = SumTemp.concat(temp)
      });
      let sumList = [];
      SumTemp.forEach(f => {
        if (!sumList.find(find => find[0] == f[0])) {
          sumList.push(f);
        }
      })
      // SumTemp.sort((a, b)=>dayjs(a.))
      let ssList = sumList.filter(f => f[1] == 10);
      let qsList = sumList.filter(f => f[1] == 6);


      // console.log(ssList, qsList);
      // console.log(SumTemp);
      this.$nextTick(() => {
        this.myChart?.setOption({
          series: [
            {
              color: '#3f51b5',
              barCategoryGap: '0%',
              barGap: '0%',
              barCategoryGap: '0',
              barWidth: '100%',
              name: '深睡',
              type: 'bar',
              data: ssList,
            },
            {
              color: '#009688',
              barCategoryGap: '0%',
              barGap: '0%',
              barCategoryGap: '0',
              barWidth: '100%',
              name: '浅睡',
              type: 'bar',
              data: qsList,
            },
            // {

            //   barCategoryGap: '0%',
            //   barGap: '0%',
            //   name: '醒/梦',
            //   type: 'bar',
            //   data: [['2023-6-15 02:00', 6], ['2023-6-15 03:00', 6]],
            // }
          ]
        })
      })


    },
    async handlePrevDay() {
      if (this.loading) return;
      this.loading = true;
      this.currentTime = this.currentTime?.subtract(1, 'day');
      this.currentRq = this.currentTime.format('MM月DD日')
      await this.getCurrentDetail();
      this.getOneSellpData();
      await this.getCurrentJkList();
      this.loading = false;
    },
    async handleNextDay() {
      if (this.loading) return;
      this.loading = true;
      this.currentTime = this.currentTime?.add(1, 'day');
      this.currentRq = this.currentTime.format('MM月DD日');
      await this.getCurrentDetail();
      this.getOneSellpData();
      await this.getCurrentJkList();
      this.loading = false;
    },
    async getCurrentDetail() {
      let { data: res } = await axios.get('/api/api/get_one_sleep_data', {
        params: {
          rq: this.currentTime?.subtract(1, 'day').format('YYYY-MM-DD') || '',
          sjhm: this.currentSjhm
        }
      });
      this.currentDetail = res.data || null
    },
    async getCurrentJkList() {
      let { data: res } = await axios.get('/api/api/get_health_page_data', {
        params: {
          rq: this.currentTime?.format('YYYY-MM-DD') || '',
          sjhm: this.currentSjhm
        }
      });
      this.currentJkList = res.data;
      // this.currentJkList = res.data.filter(f => f.rq == this.currentTime?.format('YYYY-MM-DD')) || null
      // console.log(this.currentJkList);
    },
    closeOverlay() {
      this.showDetail = false;
      setTimeout(() => {
        this.currentRq = '';
        this.currentSjhm = '';
        this.currentTime = null;
        this.currentDetail = null;
        this.currentJkList = [];
      }, 500)
    },
    handleClickItem(item) {
      // this.showDetail = true;
      this.currentSjhm = this.$route.query.sjhm;
      this.currentTime = dayjs()
      this.currentRq = this.currentTime.format('MM月DD日')
      this.getCurrentDetail();
      this.getOneSellpData();
      this.getCurrentJkList();
    },
    async get_huiyuan_zhibiao(id, sjhm = '') {
      let { data: res } = await axios.get('/api/api/get_huiyuan_zhibiao', {
        params: {
          store_id: id,
          sjhm: sjhm
        }
      });
      this.list = res.data || []
    },
    getTimeOfMin(str) {
      if (!str) return '';
      return Math.floor(str / 60) + '小时' + (str % 60) + '分钟'
    }
  },

}
</script>
<style lang="less" scoped>
.fwtc {
  .main {
    .overlayWrap {
      width: 100%;
      position: absolute;
      left: 0px;
      // background: #fff;

      // border-radius: 10px;
      padding-bottom: 32px;

      .overlayTop {
        position: absolute;
        top: 5px;
        right: 5px;
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;

        .top-img {
          width: 18px;
        }
      }

      .overlayHead {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 44px;
        background: #fff;
        border-bottom: 1px solid #f5f6f7;
        padding-top: 10px;
        padding-bottom: 10px;
        margin-top: 12px;

        .btn {
          padding: 0 24px;
          height: 24px;
          display: flex;
          align-items: center;
          color: #666;
        }
      }

      .overlayChart {
        background: #fff;
        margin-top: 12px;
        padding: 10px;

        .chartContainer {
          height: 300px;
          width: 100%;
        }
      }

      .overlayDesc {
        background: #fff;
        padding: 6px;
        margin-top: 12px;

        .descItem {
          // display: flex;
          text-align: center;
          margin: 6px;

          // justify-content: center;
          .descTitle {
            color: #3395a2;
            font-size: 15px;
            margin-bottom: 6px;
          }

          .descValue {
            color: #000;
            // font-weight: 600;
            font-size: 15px;
          }
        }
      }

      .overlayTable {
        text-align: center;

        .thead {
          color: #fff;

          .tr {
            display: flex;
            height: 32px;
            align-items: center;
            justify-content: space-between;

            background: #3395a2;

            &:nth-child(2n) {
              background: #58ded7;
            }

            .td {
              flex: 1;
              font-size: 12px;
            }
          }
        }

        .tbody {
          color: #333;
          font-size: 16px;

          .tr {
            display: flex;
            height: 32px;
            align-items: center;
            justify-content: space-between;
            border-bottom: 1px solid #f5f6f7;

            &:last-child {
              border: none;
            }

            .td {
              flex: 1;
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}
</style>
